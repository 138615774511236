// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut, updatePassword, sendEmailVerification } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";



// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAR3-wCV8OeSoxPuLOlaFdB7VAZhkdPTsM",
    authDomain: "web-app-40522.firebaseapp.com",
    projectId: "web-app-40522",
    storageBucket: "web-app-40522.appspot.com",
    messagingSenderId: "816158070108",
    appId: "1:816158070108:web:e1555e5777cf824a4ddd99",
    measurementId: "G-G8790JYRSC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize auth with the app instance
const dbFirestore = getFirestore(app); // Initialize dbFirestore with the app instance
const storage = getStorage(app);


export { auth, dbFirestore, storage, onAuthStateChanged, signOut, updatePassword, sendEmailVerification }; // Export auth and dbFirestore directly
export default app;