// src/components/marketplace/Profile.js

import React, { useState, useEffect } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { dbFirestore } from '../../firebase';
import { Icon } from '@iconify/react';
import { useParams } from 'react-router-dom';
import "./Profile.css";
import Tooltip from './Tooltip';
import LoadingSpinner from '../../LoadingSpinner';



const Profile = ({ userId, onClose }) => {

  

  const [isLoading, setIsLoading] = useState(true);

  const [displayName, setDisplayName] = useState('');
  const [chat, setChat] = useState('');
  const [call, setCall] = useState('');
  const [email, setEmail] = useState('');
  const [languages, setLanguages] = useState('');
  const [about, setAbout] = useState('');
  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [coverPhotoURL, setCoverPhotoURL] = useState('');

  const [UserDomains, setUserDomains] = useState([]);

  const [expandedRows, setExpandedRows] = useState({});



  useEffect(() => {
    const docRef = doc(dbFirestore, 'users', userId);

    getDoc(docRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setDisplayName(userData.displayName || '');
          setChat(userData.chat || '');
          setCall(userData.call || '');
          setEmail(userData.email || '');
          setLanguages(userData.languages || '');
          setAbout(userData.about || '');
          setProfilePictureURL(userData.profilePictureURL || '');
          setCoverPhotoURL(userData.coverPhotoURL || '');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        setIsLoading(false);
      });
  }, [userId]);


  useEffect(() => {
    const fetchUserDomains = async () => {
      try {
        const userDomainsRef = collection(dbFirestore, 'users', userId, 'domains');
        const userDomainsQuery = query(userDomainsRef);
        const userDomainsSnapshot = await getDocs(userDomainsQuery);
        const userDomainsData = userDomainsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserDomains(userDomainsData);
      } catch (error) {
        console.error('Error fetching user domains:', error);
      }
    };

    fetchUserDomains();
  }, [userId]);


  const renderDomainList = () => {
    const domainsToDisplay = [...UserDomains];
    const isSmallScreen = window.innerWidth <= 768;
  
    
  
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '200px', // Default maxWidth for larger screens
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#f0f0f0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '200px', // Default maxWidth for larger screens
    };
  
    // Media query to adjust styles for smaller screens
    if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }
    
  
    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };
  

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };
    

    const RegistrarbtnStyle = {
      textDecoration: 'underline', 
    }

    const buybtnStyle = {
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '2px 4px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      alignItems: 'left',
      textAlign: 'left'
    };

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };

    
    return (
      <table style={tableStyle}>
        <thead>
          <tr>
          
            <th style={thStyle}>Domain Name</th>
            <th style={thStyle}>Price</th>
            <th style={thStyle}>Buy</th>
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                <th style={thStyle}>Type</th>
                <th style={thStyle }>Description</th>
                <th style={thStyle}>Category</th>
                <th style={thStyle}>Registrar</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {domainsToDisplay.map((domain) => {
            const rows = [
              <tr key={domain.id}>
                
                <td style={tdStyle}>{domain.domainName}</td>
                <td style={tdStyle}>{domain.currency}{domain.numeric}</td>
                <td style={tdStyle}>
                  <a href={domain.buyNowUrl} target="_blank" rel="noopener noreferrer">
                    <button style={buybtnStyle}>Buy</button>
                  </a>
                </td>
                {isSmallScreen && (
                  <td style={tdStyle}>
                    <button className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                      {expandedRows[domain.id] ? '-' : '+'}
                    </button>
                  </td>
                )}
                {!isSmallScreen && (
                  <>
                    <td style={tdStyle}>{domain.type}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#f0f0f0', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', }}>
                      <Tooltip text={domain.description || 'No description is provided'}>
                        <button style={TooltipbtnStyle}>Read description</button>
                      </Tooltip>
                    </td>
                    <td style={tdStyle}>{domain.category}</td>
                    <td style={tdStyle}>
                      <a href={`https://www.whatsmydns.net/domain-name-registrar?q=${domain.domainName}`} target="_blank" rel="noopener noreferrer">
                        <button style={RegistrarbtnStyle}>View Registrar</button>
                      </a>
                    </td>
                  </>
                )}
              </tr>
            ];
  
            if (isSmallScreen && expandedRows[domain.id]) {
              rows.push(
                <tr key={`${domain.id}-expanded`}>
                  <td colSpan={isSmallScreen ? '4' : '7'}>
                    <div>
                    <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
  <strong>{domain.domainName}</strong>
</p>
                      <p><strong>Type:</strong> {domain.type}</p>
                      <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                      <p><strong>Category:</strong> {domain.category}</p>
                      <p><strong>Registrar:</strong>   <a href={`https://www.whatsmydns.net/domain-name-registrar?q=${domain.domainName}`} target="_blank" rel="noopener noreferrer">
                        <button style={RegistrarbtnStyle}>View Registrar</button>
                      </a></p>
                      

                    </div>
                  </td>
                </tr>
              );
            }
  
            return rows;
          })}
        </tbody>
      </table>
    );
  };


  

  const containerStyle = {
    backgroundColor: '#fff',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    borderRadius: '10px',
    
    
  };

  const greyIconClass = (value) => {
    return value ? '' : 'greyIcon';
  };

  const topcoverStyle = {
    background: `url(${process.env.PUBLIC_URL}/fallback-cover-photo.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '55vw', // Set the width to 100% of the viewport width
    height: '190px', // Set the desired height for your background cover
    borderRadius: '5px',
    position: 'relative',
    top: '7vw', // Shift the element down by 50% of its own height
    transform: 'translateY(-60%)', // Correct the element's position by moving it up by 50% of its own height
   
  };

  const BigclosePopupStyle = {
    position: 'fixed', 
    top: '4%', // Adjust the top position according to your design
    right: '25%', // Adjust the right position according to your design
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    background: 'white',
    color: 'blue',
  };

  const SmallclosePopupStyle = {
    alignItems: 'right',
    textAlign: 'right',
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    background: 'white',
    color: 'blue',
  };


  return (

  <div className="container" style={containerStyle}>

  
    {isLoading && <LoadingSpinner />}

{!isLoading && (
  
  <>
  <div className="section1-profile-cover">
 <div className="big-screen-button">
        <button style={BigclosePopupStyle} onClick={onClose}>
          Close Profile
        </button>
      </div>
    
  <div className="profile-top-cover" style={topcoverStyle}>
  
      </div>
      </div>
    <div className="section2-user-info">
  <div className="section-label">
    <div className="main-menu-profile">
    <div className="column-one-profile">

    <img
  src={profilePictureURL || process.env.PUBLIC_URL + '/fallback-profile-picture.png'}
  alt="Profile Picture"
  className="profile-picture"
  
/>

    </div>

    <div className="column-two-profile">
    <div className="small-screen-button">
        <button style={SmallclosePopupStyle} onClick={onClose}>
          Close Profile
        </button>
      </div>
    <h3>{displayName ? displayName : 'User'}</h3>
    <p>{languages}</p>
    </div>
  
    <div className="column-three-profile">
    <p className="profile-chat-contact">
    <Icon className={greyIconClass(chat)} icon="fluent:chat-32-filled" width="20" height="20" />
  {chat ? (
    <span className="contact-text">
      <a href={chat} target="_blank" rel="noopener noreferrer"><strong>Chat</strong></a>
    </span>
  ) : (
    <span className="not-available-text">Unavailable</span>
  )}
</p>

<p className="profile-tel-contact">
<Icon className={greyIconClass(call)} icon="fluent:call-12-filled" width="20" height="20" />
  {call ? (
    <span className="contact-text">
      <a href={`tel:${call}`}><strong>Call</strong></a>
    </span>
  ) : (
    <span className="not-available-text">Unavailable</span>
  )}
</p>

<p className="profile-mail-contact">
<Icon className={greyIconClass(email)} icon="dashicons:email-alt" width="20" height="20" />
  {email ? (
    <span className="contact-text">
      <a href={`mailto:${email}`}><strong>Email</strong></a>
    </span>
  ) : (
    <span className="not-available-text">Unavailable</span>
  )}
</p>

      </div>
      </div>
      </div>
      </div>
      <div className="section3-domain-portfolio">
        <h2>Domains portfolio</h2>
       
            {renderDomainList()}
      
      </div>
      </>
  )}
    </div>
   
   
  
    
  );
};

export default Profile;
