import React, { useState } from 'react';
import './Payments.css';

const Payments = () => {
  // State variables for form data
  const [amount, setAmount] = useState('300.00');
  const [item_name, setItemName] = useState('Webflow info');
  const [subscription_type, setSubscriptionType] = useState('1');
  const [recurring_amount, setRecurringAmount] = useState('300.00');
  const [cycles, setCycles] = useState('0');
  const [frequency, setFrequency] = useState('3');

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform any additional logic if needed before submitting the form

    // Submit the form
    document.PayFastPayNowForm.submit();
  };

  return (
    <form
      name="PayFastPayNowForm"
      action="https://payment.payfast.io/eng/process"
      method="post"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="cmd" value="_paynow" />
      <input type="hidden" name="receiver" pattern="[0-9]" value="19861495" />
      <input type="hidden" name="amount" value={amount} />
      <input type="hidden" name="item_name" maxLength="255" value={item_name} />
      <input type="hidden" name="subscription_type" pattern="1" value={subscription_type} />
      <input type="hidden" name="recurring_amount" value={recurring_amount} />
      <input type="hidden" name="cycles" pattern="[0-9]" value={cycles} />
      <input type="hidden" name="frequency" pattern="[0-9]" value={frequency} />

      <table>
        <tr>
          <td colspan={2} align="center">
            <input
              type="image"
              src="https://my.payfast.io/images/buttons/Subscribe/Primary-Large-Subscribe.png"
              alt="Subscribe"
              title="Subscribe with Payfast"
            />
          </td>
        </tr>
      </table>
    </form>
  );
};

export default Payments;
