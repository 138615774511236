// src/components/EditProfile.js

import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, dbFirestore, storage, signOut } from '../firebase';
import { useNavigate, Link } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage'; // Add this import
import Notification from '../Notification';
import { Icon } from '@iconify/react';
import LoadingSpinner from '../LoadingSpinner';


import "./EditProfile.css";
 

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [displayName, setDisplayName] = useState('');
  const [chat, setChat] = useState('');
  const [call, setCall] = useState('');
  const [email, setEmail] = useState('');
  const [languages, setLanguages] = useState('');
  const [about, setAbout] = useState('');
  const [fileNameProfilePicture, setFileNameProfilePicture] = useState(null);
  const [fileNameCoverPhoto, setFileNameCoverPhoto] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoURL, setCoverPhotoURL] = useState('');
  const [markedForDeletionProfilePicture, setMarkedForDeletionProfilePicture] = useState(false);
  const [markedForDeletionCoverPhoto, setMarkedForDeletionCoverPhoto] = useState(false);


  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleCancelUploadProfilePicture = () => {
    // Reset cover photo and file name states to revert to the fallback image
    setNotification('Profile picture will be deleted after you Save Profile');
    setProfilePicture(null);
    setFileNameProfilePicture(null);
    
    // Set a flag to mark the current cover photo for deletion
    setMarkedForDeletionProfilePicture(true);
  };

  const handleCancelUploadCoverPhoto = () => {
    // Reset cover photo and file name states to revert to the fallback image
    setCoverPhoto(null);
    
    setFileNameCoverPhoto(null);
    
    // Set a flag to mark the current cover photo for deletion
    setMarkedForDeletionCoverPhoto(true);
  };
  
  
 
const handleCoverPhotoChange = (event) => {
  const selectedFile = event.target.files[0];
  
  // Update coverPhoto state variable
  setCoverPhoto(selectedFile);

  // Update fileName state variable with the uploaded file's name
  setFileNameCoverPhoto(selectedFile.name);

  // Check if a file is selected
  if (selectedFile) {
    // Validate file type (accept only images)
    if (!selectedFile.type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }

    // Validate file size (limit to 5 MB)
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    if (selectedFile.size > maxSize) {
      alert('File size exceeds the limit of 5 MB.');
      return;
    }

    // Additional file processing logic can be added here if needed

    // Reset the file input value after processing
    event.target.value = '';
  }
};

const handleProfilePictureChange = (event) => {
  const selectedFile = event.target.files[0];

  // Update profilePicture state variable
  setProfilePicture(selectedFile);

  // Update fileName state variable with the uploaded file's name
  setFileNameProfilePicture(selectedFile.name);

  // Check if a file is selected
  if (selectedFile) {
    // Validate file type (accept only images)
    if (!selectedFile.type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }

    // Validate file size (limit to 5 MB)
    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    if (selectedFile.size > maxSize) {
      alert('File size exceeds the limit of 5 MB.');
      return;
    }

    // Additional file processing logic can be added here if needed

    // Reset the file input value after processing
    event.target.value = '';
  }
};

  

  
  const profileStyle = {
    backgroundColor: '#ffffff', // Replace with your desired background color
  };


  const handleSaveProfile = async () => {
    try {
      if (user) {
        const docRef = doc(dbFirestore, 'users', user.uid);
  
       
        if (coverPhoto) {
          const coverPhotoRef = ref(storage, `coverPhotos/${user.uid}`);
          try {
            await uploadBytes(coverPhotoRef, coverPhoto);
            const timestamp = new Date().getTime(); // Add timestamp
            const coverPhotoURL = await getDownloadURL(coverPhotoRef) + `?${timestamp}`;
        
            // Update cover photo URL in Firestore
            await setDoc(docRef, { coverPhotoURL }, { merge: true });
            
            console.log('Cover photo URL updated in Firestore');
          } catch (error) {
            console.error('Error uploading cover photo:', error);
            setNotification('Error uploading cover photo. Please try again.');
            return; // Exit the function to prevent further execution
            
          }
        } else {
          // If coverPhoto doesn't exist, update Firestore with an empty coverPhotoURL
          await setDoc(docRef, { coverPhotoURL: '' }, { merge: true });
          console.log('Cover photo URL deleted from Firestore');
        }

        

        if (profilePicture) {
          // If a profile picture is selected by the user
          const profilePictureRef = ref(storage, `profilePictures/${user.uid}`);
          try {
            // Upload the profile picture to Firebase Storage
            await uploadBytes(profilePictureRef, profilePicture);
        
            // Generate a timestamp to ensure a unique URL every time the profile picture is updated
            const timestamp = new Date().getTime();
        
            // Get the download URL of the uploaded profile picture and append the timestamp as a query parameter
            const profilePictureURL = await getDownloadURL(profilePictureRef) + `?${timestamp}`;
        
            // Update profile picture URL in Firestore document
            await setDoc(docRef, { profilePictureURL }, { merge: true });
            console.log('Profile picture URL updated in Firestore');
          } catch (error) {
            console.error('Error uploading profile picture:', error);
            setNotification('Error uploading profile picture. Please try again.');
            return; // Exit the function to prevent further execution
          }
        } else {
          // If no profile picture is selected (user canceled the upload), update Firestore with an empty profilePictureURL
          await setDoc(docRef, { profilePictureURL: '' }, { merge: true });
          console.log('Profile picture URL deleted from Firestore');
        }
        
        
  
        // Rest of the code to update other profile fields...
        
        // Update other profile data, creating a new document if it doesn't exist
        await setDoc(docRef, {
          displayName: displayName || "",
          chat: chat || "",
          call: call || "",
          email: email || "",
          languages: languages || "",
          about: about || "",
        }, { merge: true });
  
        // Reset the cover photo and file name states after successfully saving the profile
        setCoverPhoto(null);
        setProfilePicture(null);
        setFileNameCoverPhoto(null);
        setFileNameProfilePicture(null);
      
        setNotification('Profile successfully updated!');
        navigate('/dashboard');
        console.log('Profile successfully updated!');
      }
    } catch (error) {
      setNotification('Error updating profile');
      console.error('Error updating profile:', error);
    }
  };
  

  

  useEffect(() => {
    if (user) {
      const docRef = doc(dbFirestore, 'users', user.uid);

      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setDisplayName(userData.displayName);
            setChat(userData.chat || ''); // Default to empty string if not provided
            setCall(userData.call || ''); // Default to empty string if not provided
            setEmail(userData.email || ''); // Default to empty string if not provided
            setLanguages(userData.languages);
            setAbout(userData.about || '');
            setProfilePictureURL(userData.profilePictureURL || ''); // Set current profile picture URL
            setCoverPhotoURL(userData.coverPhotoURL || ''); // Set current cover photo URL
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error getting document:', error);
          setIsLoading(false);
        });
    }
  }, [user]);

  

 
  const contactlabelsStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };
  
  const labelIconStyle = {
    marginRight: '10px',
  };

  const topcoverStyle = {
    background: `url(${process.env.PUBLIC_URL}/fallback-cover-photo.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    width: '65vw', // Set the width to 100% of the viewport width
    height: '200px', // Set the desired height for your background cover
    borderRadius: '5px',
    position: 'relative',
    top: '9vw', // Shift the element down by 50% of its own height
    transform: 'translateY(-50%)', // Correct the element's position by moving it up by 50% of its own height
   
  };

  const logoutStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };

  return (

    <div className="edit-profile-container">
    <div style={profileStyle}>
      {notification && (
        <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />
      )}
      <div className="header-edit-profile">
    <div className="column-one-edit-profile">
    <div className="profile-container">
    <Icon icon="healthicons:ui-user-profile-negative" color="black" width="30" height="30" style={{ marginRight: '10px' }} />
    <h2 style={{ marginRight: '15px' }}> Edit Profile</h2>
</div>

    </div>

    <div className="column-two-edit-profile">
  
    </div>
  
    <div className="column-three-edit-profile">
    <Link to="/marketplace" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Marketplace
  </button>
</Link>

<Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Dashboard
  </button>
</Link>
<h4 ><button style={ logoutStyle } onClick={handleLogout}>Logout</button></h4>
      </div>

    </div>
    <div className="container">
    {isLoading && <LoadingSpinner />}

{!isLoading && (
  <>
 

<div className="section2">
  <div className="section-label">
    <div className="main-menu-edit-profile">
      {/* Column 1: Profile Picture */}
      

      <div className="column-one-edit-profile-picture">
  
      <label htmlFor="profile-picture-input">
  <img
    src={
      markedForDeletionProfilePicture
        ? `${process.env.PUBLIC_URL}/profile-picture.png`
        : profilePictureURL
        ? `${profilePictureURL}?${new Date().getTime()}`
        : process.env.PUBLIC_URL + '/fallback-profile-picture.png'
    }
    alt="Profile Picture"
    className="profile-picture"
  />
</label>


<input
  type="file"
  accept="image/*"
  id="profile-picture-input"
  onChange={handleProfilePictureChange}
  style={{ display: 'none' }}
/>
{profilePicture && (
  <div>
    <p>{fileNameProfilePicture}</p>
  </div>
)}
<button onClick={handleCancelUploadProfilePicture}>Remove Profile picture</button>


</div>


      {/* Column 2: Display Name and Languages */}
      <div className="column-two-edit-profile-user-name">
        <input
          type="text"
          placeholder="Display Name"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          maxLength="20"
          
        />
        <input
          type="text"
          placeholder="English, Spanish, etc"
          value={languages}
          onChange={(e) => setLanguages(e.target.value)}
          maxLength="20"
        />
      </div>

      {/* Column 3: Chat, Call, and Email */}
      
<div className="column-three-edit-profile-contacts">
  <label style={contactlabelsStyle}>
    <span style={labelIconStyle}>
      <Icon icon="fluent:chat-32-filled" color="black" width="20" height="20" />
    </span>
    <input
      type="text"
      placeholder="1 Link to either WhatsApp, Telegram, etc"
      value={chat}
      onChange={(e) => setChat(e.target.value)}
    />
  </label>
  <label style={contactlabelsStyle}>
    <span style={labelIconStyle}>
      <Icon icon="fluent:call-12-filled" color="black" width="20" height="20" />
    </span>
    <input
      type="text"
      placeholder="+X XXX XXX XXX XXX"
      value={call}
      onChange={(e) => setCall(e.target.value)}
    />
  </label>
  <label style={contactlabelsStyle}>
    <span style={labelIconStyle}>
      <Icon icon="dashicons:email-alt" color="black" width="20" height="20" />
    </span>
    <input
      type="text"
      placeholder="example@gmail.com"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
    />
  </label>
</div>
    </div>
  </div>
</div>

  <div className="section3">
      <textarea
        rows="4"
        cols="50"
        placeholder="Tell something about you..."
        value={about}
        onChange={(e) => setAbout(e.target.value)}
        maxLength="500"
      ></textarea>
  </div>

  <div className="section4">      
  <div>
  <button onClick={handleSaveProfile}>Save Profile</button>
      </div>
  </div>
  </>
  )}
</div>
</div>
</div>
 
  );
};

export default EditProfile;
