import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from "./components/authentication/SignIn";
import SignUp from "./components/authentication/SignUp";
import ResetPassword from "./components/authentication/ResetPassword";
import Marketplace from "./components/marketplace/Marketplace";
import Profile from "./components/marketplace/Profile";
import UserProfilePopup from "./components/marketplace/UserProfilePopup";
import AccountPage from "./components/AccountPage";
import AccountSettings from "./components/AccountSettings";
import ManageDomains from "./components/ManageDomains";
import Payments from "./components/Payments";
import EditProfile from './components/EditProfile';


function App() {



  return (
    
      <Router>
     
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={<AccountPage />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/manage-domains" element={<ManageDomains />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          
          <Route path="/profile/:userId" element={<UserProfilePopup/>} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/*" element={<Marketplace />} /> {/* Default route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
       
      </Router>
    
  );
}

function NotFound() {
  return (
    <div>
      <h2>404 - Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default App;
