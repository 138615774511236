// src/components/authentication/SignUp.js

import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { doc, setDoc } from 'firebase/firestore';
import { dbFirestore } from '../../firebase';
import { createUserWithEmailAndPassword, currentUser, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
 
import "./SignUp.css";
import { Icon } from '@iconify/react';


function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user, setUser] = useState(null); // Track user state
  
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState(null); // Initialize error state
  const navigate = useNavigate(); // Initialize useNavigate
  

  const generateUniqueTXTRecord = (userId) => {
    const uniqueString = `${userId}-${Math.random().toString(36).substring(2, 15)}`;
  
    // Store the TXT record in Firestore
    const userRef = doc(dbFirestore, 'users', userId);
    setDoc(userRef, { txtRecord: uniqueString }, { merge: true });
  
    return uniqueString;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
     // Reset any previous error messages
     setError(null);

     // ... (password and email validation checks)
 
     if (password !== confirmPassword) {
       // Passwords don't match
       setConfirmPasswordError("Passwords do not match");
       return;
     }
 
    

    // ... (password and email validation checks)
  
    try {
      // Sign up with email and password
      await createUserWithEmailAndPassword(auth, email, password);
       // Get the current user
       const user = auth.currentUser;
  
       // Generate and store the unique TXT record
       const txtRecord = generateUniqueTXTRecord(user.uid);
      
      // Redirect to Profile.js after successful sign-up
      navigate("/account-settings");
    } catch (error) {
      console.error("Error signing up:", error);
       
      
      // Set a custom error message based on the Firebase error code
      let errorMessage = "An error occurred during sign-up.";
      if (error.code === "auth/email-already-in-use") {
        errorMessage = "Email is already in use. Please choose a different one.";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email address. Please check your email.";
      } else if (error.code === "auth/weak-password") {
        errorMessage = "Password is too weak. Please choose a stronger one.";
      }
  
      setError(errorMessage);
    }
  };
  

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
       // Get the current user
       const user = auth.currentUser;
  
       // Generate and store the unique TXT record
       const txtRecord = generateUniqueTXTRecord(user.uid);
      navigate("/account-settings");
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  
    // Make sure to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  const homeButtonStyle = {

    position: 'fixed',
  
    left: '10px',
    top: '30px',
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
    };
  

  return (
    <main className="main">

<div className="sign-up-parent-container">
<div className="header-account-settings">

        <section className="wrapper">
     
          <div className="heading">
          <p className="text text-normal">
               <span><Link to="/marketplace" className="text text-links">Retun to Marketplace</Link></span>
              </p>
            
          </div>
          <form name="signup" className="form" onSubmit={handleSubmit}>
      
            <div className="input-control">
              <label htmlFor="email" className="input-label" hidden>
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="input-field"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                
                required

              />
                
            </div>
            <div className="input-control">
              <label htmlFor="password" className="input-label" hidden>
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                className="input-field"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
               
                required
                
              />
              
            </div>
            <div className="input-control">
              <label htmlFor="confirmPassword" className="input-label" hidden>
                Confirm Password
              </label>
              <input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                className="input-field"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                
                required
                
              />
              
            </div>
            <div className="input-control">
              <input
                type="submit"
                name="submit"
                className="input-submit"
                value="Sign Up For Marketplace"
                // Enable the submit button only when all fields are filled and passwords match
                disabled={!email || !password || !confirmPassword || password !== confirmPassword}
              />
            </div>
          </form>
          {error && <div className="error-message">{error}</div>}
          <div className="striped">
            <span className="striped-line"></span>
            <span className="striped-text">Or</span>
            <span className="striped-line"></span>
          </div>
          <div className="method">
          <div className="method-control">
              {/* Use a function to open Google sign-in popup */}
              <button onClick={handleGoogleSignIn} className="method-action">
              <Icon icon="flat-color-icons:google" width="24" height="24" className="icon-before"/>
              <span className="button-text">Sign in with Google</span>
              </button>
            </div>
            <div className="method-control">
              <p className="text text-normal">
                Already a User? <span><Link to="/sign-in" className="text text-links">Sign In to your account</Link></span>
              </p>
            </div>
          </div>
        </section>
        </div>
      </div>
    </main>
  );
}

export default SignUp;
