import React, { useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { auth, onAuthStateChanged, signOut } from '../firebase';
import { Icon } from '@iconify/react';
import './AccountPage.css'; // Import your custom CSS file



const AccountPage = () => {
  const navigate = useNavigate();


  // Function to handle user logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/marketplace'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Use useEffect to listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        // You can access user properties here, including user.id.
        console.log('User is signed in:', user);
      } else {
        // User is signed out.
        // Handle the case where the user is not authenticated.
        console.log('User is signed out.');
        navigate('/marketplace'); // Redirect to the sign-in page if the user is not signed in
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [navigate]);



  const logoutStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };

  return (
    <div>
    <div className="header-account-page"> 
    <div className="column-one-account">
    <div className="profile-container">

    <Icon icon="material-symbols:tv-signin-outline" color="black" width="30" height="30" style={{ marginRight: '10px' }} />
    <h2 style={{ margin: '0', marginRight: '15px' }}>Dashboard</h2>

</div>



    </div>

    <div className="column-two-account">
   
    </div>
  
    <div className="column-three-account">
    <Link to="/marketplace" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Marketplace
  </button>
</Link>


    
    <h4 ><button style={ logoutStyle } onClick={handleLogout}>Logout</button></h4>

      </div>

    </div>
    <div className="card-container">
        <button onClick={() => navigate('/edit-profile')} className="card-button">
          <div className="card">
            <h3>Profile </h3>
            <Icon icon="healthicons:ui-user-profile-negative" color="#000000" width="120" height="120" />
          </div>
        </button>
        <button onClick={() => navigate('/manage-domains')} className="card-button">
          <div className="card">
            <h3>Manage Domains</h3>
            <Icon icon="gridicons:domains" color="#000000" width="120" height="120" />
          </div>
        </button>
        <button onClick={() => navigate('/payments')} className="card-button">
          <div className="card">
            <h3>Payments</h3>
            <Icon icon="bi:credit-card-fill" color="#000000" width="120" height="120" />
          </div>
        </button>
        <button onClick={() => navigate('/account-settings')} className="card-button">
          <div className="card">
            <h3>Account Settings</h3>
            <Icon icon="mingcute:settings-2-fill" color="#000000" width="120" height="120" />
          </div>
        </button>
      </div>
    
  </div>

  );
}

export default AccountPage;
