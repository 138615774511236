// src/components/AccountSettings.js

import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { auth, dbFirestore, sendEmailVerification, signOut } from '../firebase';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { deleteUser } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import Notification from '../Notification';
import "./AccountSettings.css";
import { Icon } from '@iconify/react';
import LoadingSpinner from '../LoadingSpinner';



const pageStyle = {
  backgroundColor: '#ffffff', // Replace with your desired background color
};

const AccountSettings = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [organization, setorganization] = useState('');
  const [country, setcountry] = useState('');


  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [deletePassword, setDeletePassword] = useState('');
  const [confirmationText, setConfirmationText] = useState('');

  const [isEmailVerifiedState, setIsEmailVerified] = useState(false);
  
  
 
 
  
  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  useEffect(() => {
    if (user) {
      // Check if the user's email is verified
      setIsEmailVerified(user.emailVerified);

    }
  }, [user]);


  // For header information
  


  const handleSendEmailVerification = () => {
    const user = auth.currentUser;

    if (user) {
      sendEmailVerification(user)
        .then(() => {
          // Email verification sent successfully
          setNotification('Email was sent to verify Account. Please check your mailbox.');
          console.log('Email Verification sent! Check your mailbox.');
          // You can add further logic here, such as redirecting the user or showing a success message.
        })
        .catch(error => {
          // Handle errors here
          setNotification('Error sending email to verify Account: ' + error.message);
          console.error('Error sending email verification:', error);
        });
    } else {
      // No user is currently signed in
      setNotification('No user is currently signed in.');
      console.error('No user is currently signed in.');
    }
  };
  
  

  useEffect(() => {
    if (user) {
      const docRef = doc(dbFirestore, 'account', user.uid);

      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setFirstName(userData.firstName);
            setLastName(userData.lastName);
            setorganization(userData.organization);
            setcountry(userData.country);
            
            
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error getting document:', error);
          setIsLoading(false);
        });
    }
  }, [user]);

  const handleAccountSettings = async () => {
    if (user) {
      const docRef = doc(dbFirestore, 'account', user.uid);
  
      try {
        // Update user's first name and last name
        await setDoc(docRef, {
          firstName: firstName || "", // Provide an empty string as a default value
          lastName: lastName || "",
          organization: organization || "",
          country: country || "",
        }, { merge: true });
        setNotification('Account is successfully updated.');
        console.log('Account document updated successfully.');
      } catch (error) {
        setNotification('Error in updating Account.');
        console.error('Error updating account document:', error);
      }
    }
  };

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword) {
      console.log('Please enter both current and new passwords.');
      return;
    }
  
    const user = auth.currentUser;
    
    // Create a credential using the user's email and current password
    const credentials = EmailAuthProvider.credential(user.email, currentPassword);
  
    try {
      // Reauthenticate the user with their current password
      await reauthenticateWithCredential(user, credentials);
    
      // If reauthentication is successful, change the password
      await updatePassword(user, newPassword);
    
      // Password changed successfully
      setNotification('Password changed successfully.');
      console.log('Password changed successfully.');
    } catch (error) {
      // Handle reauthentication or password change error
      setNotification('Error in changing password.');
      console.error('Error changing password:', error);
    }
  };

  const handleCloseAccount = async () => {
    if (!deletePassword || confirmationText !== 'CLOSE ACCOUNT') {
      setNotification('Invalid confirmation or missing current password. Account not closed.');
      console.log('Invalid confirmation or missing current password. Account not closed.');
      return;
    }
  
    try {
      const user = auth.currentUser;
  
      // Reauthenticate the user with their current password
      const credentials = EmailAuthProvider.credential(user.email, deletePassword);
      await reauthenticateWithCredential(user, credentials);
  
      // Delete user data from Firestore
      const docRef = doc(dbFirestore, 'account', user.uid);
      await deleteDoc(docRef);
  
      // Delete the user's account
      await deleteUser(user);
  
      // User account closed successfully
      navigate('/signup');
      setNotification('Account closed successfully.');
      console.log('Account closed successfully.');
    } catch (error) {
      // Handle errors, e.g., reauthentication error, database deletion error, etc.
      setNotification('Error closing account.');
      console.error('Error closing account:', error);
    }
  };
  
  
  const logoutStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };
  
  

  return (
    <div>
   <div className="header-account-settings">
    <div className="column-one-account-settings">
    <div className="profile-container">
    <Icon icon="mingcute:settings-2-fill" color="black" width="30" height="30" style={{ marginRight: '10px' }} />
    <h2 style={{ marginRight: '15px' }}> Account Settings</h2>
</div>

    </div>

    <div className="column-two-account-settings">
  
    </div>
  
    <div className="column-three-account-settings">
    <Link to="/marketplace" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Marketplace
  </button>
</Link>

<Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Dashboard
  </button>
</Link>
<h4 ><button style={ logoutStyle } onClick={handleLogout}>Logout</button></h4>
      </div>

    </div>
    <div className="parent-container">
    {isLoading && <LoadingSpinner />}

{!isLoading && (
  <>
    <div className="settings-container">
    
  {notification && (
    <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />
  )}
  {/* Verification */}
  <div className="verification-section">
  {isEmailVerifiedState ? (
        <p><Icon icon="octicon:verified-16" color="green" width="20" height="20" /> Account is verified</p>
    ) : (
        <p>
            <Icon icon="octicon:unverified-16" color="grey" width="20" height="20"/> Account is not verified
        </p>
    )}
    {!isEmailVerifiedState && (
      <button onClick={handleSendEmailVerification}>Request Email to verify Account.</button>
    )}
  </div>
  {/* User Info */}
  <div style={pageStyle} className="user-info-section">
    <h3>User Account Information</h3>
    <ul>
      <li>
        <strong>First Name (for individuals):</strong>{" "}
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </li>
      <li>
        <strong>Last Name/ Surname (for individuals):</strong>{" "}
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </li>
      <li>
        <strong>Company/ Organization (for businesses):</strong>{" "}
        <input
          type="text"
          placeholder="Company/ Organization"
          value={organization}
          onChange={(e) => setorganization(e.target.value)}
        />
      </li>
      <li>
        <strong>Country:</strong>{" "}
        <input
          type="text"
          placeholder="Country"
          value={country}
          onChange={(e) => setcountry(e.target.value)}
        />
      </li>
    </ul>
   
      <button onClick={handleAccountSettings}>Save Account Settings</button>
   
  </div>

  {/* Change Password */}
  <div className="password-change-section">
    <h3>Change Password</h3>
    <ul>
      <li>
        <strong>Current Password:</strong>{" "}
        <input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </li>
      <li>
        <strong>New Password:</strong>{" "}
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </li>
      <li>
        <strong>Confirm New Password:</strong>{" "}
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </li>
      <li>
        <button onClick={handleChangePassword}>Change Password</button>
      </li>
    </ul>
  </div>

  {/* Close Account */}
  <div className="account-close-section">
    <h3>Close Account</h3>
    <p>
      <strong>Please enter your current password</strong>
      <input
        type="password"
        placeholder="Current Password"
        value={deletePassword}
        onChange={(e) => setDeletePassword(e.target.value)}
      />
    </p>
    <p>
      <strong>Please enter "CLOSE ACCOUNT" to confirm:</strong>
    </p>
    <input
      type="text"
      placeholder="Confirmation Text"
      value={confirmationText}
      onChange={(e) => setConfirmationText(e.target.value)}
    />
    <p>
      <button onClick={handleCloseAccount}>Close Account</button>
    </p>
  </div>
  
</div>

</>
  )}
</div>
</div>

  );
};

export default AccountSettings;

