// src/components/ManageDomains.js

import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  doc,
  setDoc,
} from 'firebase/firestore';
import { auth, dbFirestore, signOut } from '../firebase';
import Notification from '../Notification';
import { useNavigate, Link } from "react-router-dom";
import "./ManageDomains.css";
import { Icon } from '@iconify/react';
import LoadingSpinner from '../LoadingSpinner';


const profileStyle = {
  backgroundColor: '#ffffff', // Replace with your desired background color
};

function ManageDomains() {
  const [user, setUser] = useState(null);
 
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [txtRecord, setTxtRecord] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const [notification, setNotification] = useState(null);


  const [selectedCategory, setSelectedCategory] = useState(null);

  const [numeric, setNumeric] = useState('');



  const [domains, setDomains] = useState([]);
  const [newDomain, setNewDomain] = useState({
    // Your domain properties here
  });
  const [showEmptyRow, setShowEmptyRow] = useState(false);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  const fetchDomains = async () => {
    try {
      if (user) {
        const userDomainsRef = collection(dbFirestore, 'users', user.uid, 'domains');
        const q = query(userDomainsRef);
        const querySnapshot = await getDocs(q);
        const domainsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDomains(domainsData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching domains:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchDomains();
    }
  }, [user]);

  

  const addDomain = async () => {
    if (user && user.uid) {
      try {
       
  
        // Create a reference to the 'domains' collection under the user's UID
        const userDomainsRef = collection(dbFirestore, 'users', user.uid, 'domains');
  
        const domainToAdd = {
          domainName: newDomain.domainName || '',
          price: newDomain.price || '',
          currency: newDomain.currency || '',
          numeric: newDomain.numeric || '',
          category: newDomain.category || '',
          type: newDomain.type || '',
          buyNowUrl: newDomain.buyNowUrl || '',
          description: newDomain.description || '',
          dateListed: getFormattedDate(),
          isPublic: true,
          ownerId: user.uid,
          isVerified: false,
         
        };
  
        // Add the domain to Firestore
        await addDoc(userDomainsRef, domainToAdd);
  
        // Fetch updated domains and update state
        fetchDomains();
  
        // Reset form fields and state
        setShowEmptyRow(false);
        setNewDomain({
          domainName: '',
          price: '',
          currency: '',
          numeric: '',
          category: '',
          type: '',
          buyNowUrl: '',
          description: '',
        });
      } catch (error) {
        console.error('Error adding domain:', error);
      }
    } else {
      console.error('User or User ID is not available.');
    }
  };
    
  

  const removeDomain = async (domainId) => {
    if (user) {
      const userDomainDocRef = doc(dbFirestore, 'users', user.uid, 'domains', domainId);

      try {
        await deleteDoc(userDomainDocRef);
        fetchDomains();
        setNotification('Domain was successfully removed');
      } catch (error) {
        setNotification('Error in removing domain');
        console.error('Error removing domain:', error);
      }
    }
  };

  const saveChanges = async () => {
    // Loop through the domains array and save each updated domain to Firestore
    if (user) {
      try {
        const promises = domains.map(async (domain) => {
          const userDomainDocRef = doc(
            dbFirestore,
            'users',
            user.uid,
            'domains',
            domain.id
          );
          await setDoc(userDomainDocRef, domain, { merge: true });
        });
        await Promise.all(promises);
        fetchDomains();
        setNotification('Changes were successfully done.');
      } catch (error) {
        console.error('Error saving changes:', error);
      }
    }
  };

  const getFormattedDate = () => {
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    const currentDate = new Date().toLocaleDateString(undefined, options);
    return currentDate;
  };

  const handleInputChange = (event, domainId, field) => {
    if (field === 'category') {
      const selectedCategory = event.target.value;
      console.log('Selected Category:', selectedCategory);
      setSelectedCategory(selectedCategory); // Update the selected category in state
  
      // Update the category property of the domain
      const updatedDomains = domains.map((domain) => {
        if (domain.id === domainId) {
          return {
            ...domain,
            category: selectedCategory,
          };
        }
        return domain;
      });
      setDomains(updatedDomains);
    } else {
      const updatedDomains = domains.map((domain) => {
        if (domain.id === domainId) {
          return {
            ...domain,
            [field]: event.target.value,
          };
        }
        return domain;
      });
      setDomains(updatedDomains);
    }
  };


  useEffect(() => {
    const fetchTxtRecord = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(dbFirestore, 'users', auth.currentUser.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.txtRecord) {
              setTxtRecord(userData.txtRecord);

              // Call the server-side API endpoint for domain ownership verification
              const response = await fetch(`/api/verify-domain-ownership?domain=${userData.domain}&txtRecord=${userData.txtRecord}`);
              const data = await response.json();

              if (data.isVerified) {
                // Domain ownership is verified, perform necessary actions
              } else {
                // Domain ownership verification failed
                console.error('Domain ownership verification failed for domain:', userData.domain);
              }
            }
          }
        } catch (error) {
          console.error('Error fetching TXT record:', error);
        }
      }
    };

    fetchTxtRecord(); // Call the function to fetch txtRecord and verify ownership on component mount
  }, []); // Empty dependency array ensures this effect runs once after the initial render

   
  


  const copyToClipboard = () => {
    navigator.clipboard.writeText(txtRecord);
    setIsCopied(true);
  };
  

  const handleNumericInputChange = (event, domainId) => {
    const enteredValue = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
    const numericValue = enteredValue !== '' ? parseInt(enteredValue, 10) : '';
    const formattedValue = numericValue ? numericValue.toLocaleString('en-US') : '';
    const updatedDomains = domains.map((domain) => {
      if (domain.id === domainId) {
        return {
          ...domain,
          numeric: formattedValue,
        };
      }
      return domain;
    });
    setDomains(updatedDomains);
  };
  
  
  
  
     
 
  // Render the table rows
const renderTableRows = () => {
  
 
  return domains.map((domain) => (

    
    <tr key={domain.id}>
      <td>
      <input
  type="text"
  placeholder="example.com"
  value={domain.domainName}
  onChange={(e) => {
    const enteredValue = e.target.value;
    // Use a regular expression to validate the domain name format
    const isValidDomain = /^[a-zA-Z0-9.-]*$/.test(enteredValue);

    
    if (isValidDomain) {
      handleInputChange(e, domain.id, 'domainName');
    } else {
      // Handle invalid input, e.g., show an error message or prevent the input
    }
  }}
/>
      </td>
      <td>
      
        <select
          value={domain.currency}
          onChange={(e) => handleInputChange(e, domain.id, 'currency')}
        >
          <option value="">Select Currency</option>
          <option value="$">$/ USD /US Dollar</option>
          <option value="€">€/ EUR /Euro</option>
          <option value="£">£/ GBP /British Pound</option>
          <option value="¥">¥ /Yen</option>
          <option value="₣">₣ /Franc</option>
          <option value="₹">₹ /Rupee</option>
          <option value="₽">₽ /RUB /Russian Ruble</option>
          <option value="A$">A$ /AUD /Australian Dollar</option>
          <option value="C$">C$ /CAD /Canadian dollar</option>
          <option value="HK$">HK$ /Hong Kong Dollar</option>
          <option value="R$">R$ /BRL /Brazilian real</option>
          <option value="Mex$">Mex$ /MXN /Mexican Peso</option>
          <option value="R">R /South African Rand</option>
          <option value="د.إ"> د.إ /Dirham</option>
          <option value="د.ك"> د.ك /Dinar</option>
          
        </select>
      
        <input
            type="text"
            placeholder="Enter Amount"
            value={domain.numeric}
            onChange={(e) => handleNumericInputChange(e, domain.id)}
          />

     
      </td>
      <td>
        <select
          value={domain.category}
          onChange={(e) => handleInputChange(e, domain.id, 'category')}
        >
          <option value="">Select Category</option>
          <option value="Sport">Sport</option>
          <option value="Entertainment">Entertainment</option>
          <option value="Blog">Blog</option>
          <option value="Finance">Finance</option>
          <option value="Travel and Hospitality">Travel and Hospitality</option>
          <option value="Education and Academic">Education and Academic</option>
          <option value="Health">Health</option>
          <option value="Internet services">Internet services</option>
          <option value="Design">Design</option>
          <option value="E-commerce and Online Stores">E-commerce and Online Stores</option>
          <option value="Business">Business</option>
          <option value="Non-profit">Non-profit</option>
          <option value="Other">Other</option>
          {/* Add more options as needed */}
        </select>
      </td>
      <td>
        <select
          value={domain.type}
          onChange={(e) => handleInputChange(e, domain.id, 'type')}
        >
          <option value="">Select Type</option>
          <option value="Domain Only">Domain Only</option>
          <option value="Live Site">Live Site</option>
        </select>
      </td>
      <td>
        <input
          type="url"
          placeholder="Paste Buy Now URL link"
          value={domain.buyNowUrl}
          onChange={(e) => handleInputChange(e, domain.id, 'buyNowUrl')}
        />
      </td>
      <td>
        <textarea
          placeholder="E.g. This domain can be useful to cover sports athletes. (limit to 300 characters)"
          value={domain.description}
          onChange={(e) => handleInputChange(e, domain.id, 'description')}
          maxLength={300}
        />
      </td>
      <td>{domain.dateListed}</td>
      
      <td>
        <button style={ removeDomainbtnStyle } onClick={() => removeDomain(domain.id)}>Remove</button>
      </td>
    </tr>
  ));
};


const logoutStyle = {
  margin: '5px',
  padding: '6px 12px',
  fontSize: '12px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#4f4f4f',
  color: 'white',
  cursor: 'pointer'
};

const removeDomainbtnStyle = {
  margin: '5px',
  padding: '4px 8px',
  fontSize: '12px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#4f4f4f',
  color: 'white',
  cursor: 'pointer'
};

const saveChangesbtnStyle = {
  margin: '5px',
  padding: '6px 12px',
  fontSize: '12px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#4f4f4f',
  color: 'white',
  cursor: 'pointer'
};

const addDomainbtnStyle = {
  margin: '5px',
  padding: '6px 12px',
  fontSize: '12px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#4f4f4f',
  color: 'white',
  cursor: 'pointer'
};

  return (
    
    <div style={profileStyle}>
      
      {notification && (
        <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />
      )}
      <div className="header-manage-domains">
        
    <div className="column-one">
    <div className="profile-container">
   
</div>

    </div>

    <div className="column-two-manage-domains">
  
    </div>
  
    <div className="column-three-manage-domains">
    <Link to="/marketplace" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Marketplace
  </button>
</Link>

<Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    Dashboard
  </button>
</Link>
<h4 ><button style={ logoutStyle } onClick={handleLogout}>Logout</button></h4>
      </div>
      
    </div>
    <div>
    {isLoading && <LoadingSpinner />}

{!isLoading && (
  <>
  
      <table>
       
        <thead>
          <tr>
            <th>Domain name</th>
            <th>Price</th>
            <th>Category</th>
            <th>Type</th>
            <th>Buy Now</th>
            <th>Description</th>
            <th>Date listed</th>
            
          </tr>
        </thead>
        <tbody>
  {isLoading && (
    <tr>
      <td colSpan="7"><LoadingSpinner /></td>
    </tr>
  )}
  {!isLoading && showEmptyRow && (
    <tr>
      <td>
        <input
          type="text"
          placeholder="example.com"
          value={newDomain.domainName}
          onChange={(e) => {
            // Handle input change for domain name
          }}
        />
      </td>
      <td>
      <select
          value={newDomain.currency}
          onChange={(e) => {
            
          }}
        >
         
        </select>
        <input
                  type="text"
                  placeholder="Enter Amount"
                  value={newDomain.numeric}
                  onChange={(e) => setNewDomain({ ...newDomain, numeric: e.target.value.replace(/\D/g, '') })}
                />
      </td>
      <td>
        <select
          value={newDomain.category}
          onChange={(e) => {
            // Handle input change for domain category
          }}
        >
          {/* Options for domain category */}
        </select>
      </td>
      <td>
        <select
          value={newDomain.type}
          onChange={(e) => {
            // Handle input change for domain type
          }}
        >
          {/* Options for domain type */}
        </select>
      </td>
      <td>
        <input
          type="url"
          placeholder="Paste Buy Now URL a link to a buy page"
          value={newDomain.buyNowUrl}
          onChange={(e) => {
            // Handle input change for Buy Now URL
          }}
        />
      </td>
      <td>
        <textarea
          placeholder="E.g. This domain can be useful to cover sports athletes. (limit to 300 characters)"
          value={newDomain.description}
          onChange={(e) => {
            // Handle input change for domain description
          }}
          maxLength={300}
        />
      </td>
      <td>{getFormattedDate()}</td>
    </tr>
  )}
  {!isLoading && renderTableRows()}
</tbody>

      </table>

      <button style={addDomainbtnStyle} onClick={addDomain}>Add Domain</button>
      <button style={saveChangesbtnStyle} onClick={saveChanges}>Save Changes</button>
      </>
  )}
    </div>
    
  </div>
  );
}

export default ManageDomains;
