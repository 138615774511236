// src/components/marketplace/Marketplace.js


import React, { useState, useEffect, useMemo } from 'react';
import { Link, Route, Routes } from "react-router-dom";
import { auth } from "../../firebase";
import { Icon } from '@iconify/react';
import _ from 'lodash';


import "./Marketplace.css";

import UserProfilePopup from './UserProfilePopup';
import Profile from './Profile';
import Tooltip from './Tooltip';
import LoadingSpinner from '../../LoadingSpinner';
import { collection, query, where, getDocs } from 'firebase/firestore'; // You may not need all the Firestore functions used in ManageDomains.js
import { dbFirestore } from '../../firebase'; // Import your Firebase Firestore configuration

function Marketplace() {
  const user = auth.currentUser;
  
  const [isLoading, setIsLoading] = useState(true);

  const [isPopupVisible, setPopupVisible] = useState(false);

  const [isProfilePopupVisible, setProfilePopupVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to manage selected user ID for the popup
  

  const [users, setUsers] = useState([]);
  

  const [publicDomains, setPublicDomains] = useState([]); // Define publicDomains state
  const [allUserDomains, setAllUserDomains] = useState([]); // Define allUserDomains state
  const [selectedCategory, setSelectedCategory] = useState('Category');
 
  const [isFiltering, setIsFiltering] = useState(false);
  const [showGeneralButton, setShowGeneralButton] = useState(true);


  const [filteredDomainsAll, setFilteredDomainsAll] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [searchType, setSearchType] = useState('All'); // Default to 'All' for type
  const [searchCurrency, setSearchCurrency] = useState('All');
  const [minNumeric, setMinNumeric] = useState('');
  const [maxNumeric, setMaxNumeric] = useState('');
  
  const [searchCategory, setSearchCategory] = useState('All'); // Default to 'All' for category
  
  const [isFilteringActive, setIsFilteringActive] = useState(false);

  const [userId, setUserId] = useState(null);

 
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(15);

  const [expandedRows, setExpandedRows] = useState({});
  
  const isSmallScreen = window.innerWidth <= 768;
  const [showSmallScreenMenu, setShowSmallScreenMenu] = useState(false);



  const showProfilePopup = (userId) => {
    setSelectedUserId(userId); // Set the selected userId to display in the profile popup
    setPopupVisible(true); // Show the popup
  };


  // Define filter functions

// Filter by name
const filterByName = (domain, domainName) => {
  
  return domainName === '' || domain.domainName.toLowerCase().includes(domainName.toLowerCase());

};

// Filter by type
const filterByType = (domain, type) => {
  return type === 'All' || domain.type === type;
};


// Filter by currency
const filterByCurrency = (domain, currency) => {
  return currency === 'All' || domain.currency === currency;
};



// Filter by Numeric
const filterByNumericRange = (domain, minNumeric, maxNumeric) => {
  const domainNumeric = parseFloat(domain.numeric);
  console.log('Parsed Domain Numeric:', domainNumeric);

  const isMinNumericValid = minNumeric === '' || domainNumeric >= parseFloat(minNumeric);
  console.log('Is Min Numeric Valid:', isMinNumericValid);

  const isMaxNumericValid = maxNumeric === '' || domainNumeric <= parseFloat(maxNumeric);
  console.log('Is Max Numeric Valid:', isMaxNumericValid);

  return isMinNumericValid && isMaxNumericValid;
};





// Filter by category
const filterByCategory = (domain, category) => {
  return category === 'All' || domain.category === category;
};


const applyFilters = () => {
  const filtered = allDomains.filter((domain) => {
    const matchesName = filterByName(domain, nameFilter);
    const matchesType = filterByType(domain, searchType);
    const matchesCurrency = filterByCurrency(domain, searchCurrency);
    const matchesNumeric = filterByNumericRange(domain, minNumeric, maxNumeric);
    const matchesCategory = filterByCategory(domain, searchCategory);

    

    return matchesName && matchesType && matchesNumeric && matchesCategory && matchesCurrency;

  });

  setFilteredDomainsAll(filtered);
};



// Combine public domains and domains from all users
const allDomains = [...publicDomains, ...allUserDomains];


const filteredDomains = useMemo(() => {
  console.log('Calculating filtered domains based on selectedCategory and allDomains:', selectedCategory, allDomains);
  return selectedCategory
    ? allDomains.filter((domain) => domain.category === selectedCategory)
    : allDomains;
}, [selectedCategory, allDomains]);

useEffect(() => {
  console.log('Executing useEffect for filteredDomains:', filteredDomains);
  // You don't need to setFilteredDomains here because filteredDomains is derived from useMemo.
}, [filteredDomains]);

  
  
  // Marketplace.js
useEffect(() => {
  console.log("UseEffect 1: Fetching data...");
  // Fetch all public domains
  const fetchPublicDomains = async () => {
    try {
      const publicDomainsRef = collection(dbFirestore, 'domains');
      const publicQuerySnapshot = await getDocs(publicDomainsRef);
      const publicDomainsData = publicQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPublicDomains(publicDomainsData);
    } catch (error) {
      console.error('Error fetching public domains:', error);
    }
  };

  // Fetch domains from all users (including public domains)
  const fetchAllUserDomains = async () => {
    try {
      const allUserDomains = [];

      // Fetch domains from all users
      const usersRef = collection(dbFirestore, 'users');
      const userQuerySnapshot = await getDocs(usersRef);
      const userDocs = userQuerySnapshot.docs;

      // Iterate over user documents and fetch their domains
      for (const userDoc of userDocs) {
        const userUid = userDoc.id;
        const userDomainsRef = collection(dbFirestore, 'users', userUid, 'domains');
        const userQuerySnapshot = await getDocs(userDomainsRef);
        const userDomainsData = userQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        allUserDomains.push(...userDomainsData);
      }

      setAllUserDomains(allUserDomains);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user domains:', error);
      setIsLoading(false);
    }
  };

  fetchPublicDomains(); // Fetch all public domains
  fetchAllUserDomains(); // Fetch domains from all users


    
}, []);


// to see users of this app
useEffect(() => {
  const fetchUsers = async () => {
    try {
      const usersRef = collection(dbFirestore, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    
    } catch (error) {
      console.error('Error fetching users:', error);
      
    }
  };

  fetchUsers();
}, []);


const [currentDomainIndex, setCurrentDomainIndex] = useState(0);

useEffect(() => {
  const rotateDomains = () => {
    setCurrentDomainIndex((prevIndex) => (prevIndex + 1) % allDomains.length);
  };

  const rotationInterval = 5000; // Rotate every 5 seconds (adjust as needed)

  const rotationTimer = setInterval(rotateDomains, rotationInterval);

  return () => {
    clearInterval(rotationTimer); // Clear the interval when the component unmounts or dependencies change
  };
}, [allDomains]);

const currentDomain = allDomains[currentDomainIndex];

const [currentRowIndex, setCurrentRowIndex] = useState(0);


useEffect(() => {
  const rotateRows = () => {
    setCurrentRowIndex((prevIndex) => (prevIndex + 1) % allDomains.length);
  };

  const rotationInterval = 5000; // Rotate every 5 seconds (adjust as needed)

  const rotationTimer = setInterval(rotateRows, rotationInterval);

  return () => {
    clearInterval(rotationTimer); // Clear the interval when the component unmounts or dependencies change
  };
}, [allDomains]);

const currentRow = allDomains[currentRowIndex];



  const renderAllDomainsTable = ({ showGeneralButton, isFilteringActive, toggleFiltering }) => {  
  
    


  const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
  };

  const thStyle = {
    backgroundColor: '#f0f0f0',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '200px', // Default maxWidth for larger screens
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #ddd',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '200px', // Default maxWidth for larger screens
  };

  // Media query to adjust styles for smaller screens
  if (window.innerWidth <= 768) {
    tdStyle.maxWidth = '120px'; // Adjust maxWidth for smaller screens
    thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
  }

  const categoriesbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer',
    textAlign: 'right'
  };

  const filterbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };

  const TooltipbtnStyle = {
    backgroundColor: '#eeeeee',
    color: '#000000',
    padding: '2px 4px', // Adjust padding as needed for your content
    border: 'none',
    borderRadius: '5px', // Border radius for rounded corners
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center', // Center content vertically
    justifyContent: 'center', // Center content horizontally
   
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
  };

  const RegistrarbtnStyle = {
    textDecoration: 'underline', 
  }

  

  const buybtnStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '2px 4px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    alignItems: 'left',
    textAlign: 'left'
  };


  const NxtNavbuttonStyle = {

  position: 'absolute',

  right: '10px',
 
  
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'right', 
 
    margin: '0 5px', // Margin between buttons
    padding: '8px', // Padding inside buttons
    cursor: 'pointer', // Cursor style
  };

  const PreNavbuttonStyle = {

    position: 'absolute',

  right: '40px',
 
  
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'right', 
 
    margin: '0 5px', // Margin between buttons
    padding: '8px', // Padding inside buttons
    cursor: 'pointer', // Cursor style
  };
  
  const disabledNxtNavButtonStyle = {
    ...NxtNavbuttonStyle,

    cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
    color: 'grey', // Set text color to grey for disabled buttons

  alignItems: 'center',
  justifyContent: 'center',
  };

  const disabledPreNavButtonStyle = {
    ...PreNavbuttonStyle,

    cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
    color: 'grey', // Set text color to grey for disabled buttons

  alignItems: 'center',
  justifyContent: 'center',
  };
  
  
  const NaviconStyle = {
    marginRight: '5px', // Margin between icon and text
  };




  const handleGeneralButtonClick = () => {
    setShowGeneralButton(!showGeneralButton);

    // Toggle filtering when the button is clicked
    toggleFiltering(!isFilteringActive);
  };

  const handlePreviousPage = () => {
    const newStartIndex = Math.max(startIndex - 15, 0);
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + 15);
  };
  
  const handleNextPage = () => {
    const newStartIndex = endIndex;
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + 15);
  };
  


  const filteredDomainsToRender = isFilteringActive ? filteredDomainsAll : allDomains;

  const domainsToDisplay = isFilteringActive ? filteredDomainsToRender.slice(startIndex, endIndex) : allDomains.slice(startIndex, endIndex);

  const toggleRowExpansion = (domainId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [domainId]: !prevExpandedRows[domainId],
    }));
  };

  const isSmallScreen = window.innerWidth <= 768;


  return (
    <div>
      


<div>
    <button style={filterbtnStyle} onClick={() => setIsFilteringActive(!isFilteringActive)}>
        {isFilteringActive ? 'Show All Domains' : 'Apply Filters'}
    </button>
    
    {!isFilteringActive && (
        <button style={categoriesbtnStyle} onClick={() => setShowGeneralButton(false)}>
            Categories
        </button>
    )}

   

<button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= (isFilteringActive ? filteredDomainsToRender.length : allDomains.length) ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= (isFilteringActive ? filteredDomainsToRender.length : allDomains.length)}
>
  
  <Icon icon="carbon:next-filled" color={endIndex >= (isFilteringActive ? filteredDomainsToRender.length : allDomains.length) ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>



</div>
   

<table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Domain Name</th>
            <th style={thStyle}>Price</th>
            <th style={thStyle}>Buy</th>
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                <th style={thStyle}>Type</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>View Profile</th>
                <th style={thStyle}>Category</th>
                <th style={thStyle}>Registrar</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={isSmallScreen ? '3' : '8'}><LoadingSpinner /></td>
            </tr>
          ) : (
            domainsToDisplay.map((domain) => {
              const rows = [
                <tr key={domain.id}>
                  <td style={tdStyle}><strong>{domain.domainName}</strong></td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#ffffff', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', }}>
                        <Tooltip text={domain.description || 'No description is provided'}>
                          <button style={TooltipbtnStyle}>Read description</button>
                        </Tooltip>
                      </td>
                  <td style={tdStyle}>
                    <a href={domain.buyNowUrl} target="_blank" rel="noopener noreferrer">
                      <button style={buybtnStyle}>Buy</button>
                    </a>
                  </td>
                  {isSmallScreen && (
                    <td style={tdStyle}>
                      <button className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                        {expandedRows[domain.id] ? '-' : '+'}
                      </button>
                    </td>
                  )}
                  {!isSmallScreen && (
                    <>
                      <td style={tdStyle}>{domain.type}</td>

                      
                      <td style={tdStyle}>
                        <button onClick={() => showProfilePopup(domain.ownerId || 'defaultUserId')}>
                          <Icon icon="akar-icons:info-fill" width="24" height="24" />
                        </button>
                      </td>
                      <td style={tdStyle}>{domain.category}</td>
                      <td style={tdStyle}>
                        <a href={`https://www.whatsmydns.net/domain-name-registrar?q=${domain.domainName}`} target="_blank" rel="noopener noreferrer">
                          <button style={RegistrarbtnStyle}>View Registrar</button>
                        </a>
                      </td>
                    </>
                  )}
                </tr>
              ];

              if (isSmallScreen && expandedRows[domain.id]) {
                rows.push(
                  <tr key={`${domain.id}-expanded`}>
                    <td colSpan={isSmallScreen ? '3' : '8'}>
                      <div>
                      <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
  <strong>{domain.domainName}</strong>
</p>
                        <p><strong>Type:</strong> {domain.type}</p>
                        <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                        <p>
  <strong>Seller Profile:</strong>{" "}
  <span
    style={{
      cursor: "pointer",
     
      color: "blue",
    }}
    onClick={() => showProfilePopup(domain.ownerId || "defaultUserId")}
  >
    View Profile
  </span>
</p>

                        <p><strong>Category:</strong> {domain.category}</p>
                        <p><strong>Registrar:</strong> <a href={`https://www.whatsmydns.net/domain-name-registrar?q=${domain.domainName}`} target="_blank" rel="noopener noreferrer">
                          <button style={RegistrarbtnStyle}>View Registrar</button>
                        </a></p>
                        

                      </div>
                    </td>
                  </tr>
                );
              }

              return rows;
            })
          )}
        </tbody>
      </table>

      

      {isPopupVisible && (
        <div className="user-profile-popup">
          <Profile userId={selectedUserId} onClose={() => setPopupVisible(false)} />
        </div>
      )}
    </div>
  );
};







// Inside the renderDomainList function, update the button as follows:
const renderDomainList = () => {
 

    const tableStyle = {
      borderCollapse: 'collapse', // Collapse table borders
      width: '100%', // Make the table take up the available width
    };
    
   
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '200px', // Default maxWidth for larger screens
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '200px', // Default maxWidth for larger screens
    };
  
    // Media query to adjust styles for smaller screens
    if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }

    const closePopupStyle = {
      position: 'absolute',
      top: '10px', // Adjust the top position according to your design
      right: '10px', // Adjust the right position according to your design
      cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const RegistrarbtnStyle = {
      textDecoration: 'underline', 
    }

    const buybtnStyle = {
      backgroundColor: '#007bff',
      color: '#fff',
      padding: '3px 6px',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      alignItems: 'left',
      textAlign: 'left'
    };



    const filteredDomainsToDisplay = filteredDomains.slice(startIndex, endIndex);

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };
  
    const isSmallScreen = window.innerWidth <= 768;

    
    const handleCategoryClick = (category) => {
      setSelectedCategory(category);
      setIsFiltering(!!category); // Set isFiltering to true if category is selected, otherwise false
      if (isSmallScreen) {
        setShowSmallScreenMenu(false);
      }
    };
  
    const toggleSmallScreenMenu = () => {
      setShowSmallScreenMenu(!showSmallScreenMenu);
    };

 
  return (

    <div>
      
        {!showGeneralButton && (
          
          <div className="categories-menu">
          {isSmallScreen && (
            <div className={`menu-icon ${showSmallScreenMenu ? 'active' : ''}`} onClick={toggleSmallScreenMenu}>
              ☰ {/* Hamburger menu icon */}
            </div>
          )}
          {showSmallScreenMenu && isSmallScreen && (
            <div className="category-buttons">
              <button onClick={() => handleCategoryClick('Sport')}>Sport</button>
              <button onClick={() => handleCategoryClick('Entertainment')}>Entertainment</button>
        <button onClick={() => handleCategoryClick('Blog')}>Blog</button>
        <button onClick={() => handleCategoryClick('Finance')}>Finance</button>
        <button onClick={() => handleCategoryClick('Travel and Hospitality')}>Travel</button>
        <button onClick={() => handleCategoryClick('Education and Academic')}>Education and Academic</button>
        <button onClick={() => handleCategoryClick('Health')}>Health</button>
        <button onClick={() => handleCategoryClick('Internet services')}>Internet services</button>
        <button onClick={() => handleCategoryClick('Design')}>Design</button>
        <button onClick={() => handleCategoryClick('E-commerce')}>E-commerce</button>
        <button onClick={() => handleCategoryClick('Business')}>Business</button>
        <button onClick={() => handleCategoryClick('Non-profit')}>Non-profit</button>
        <button onClick={() => handleCategoryClick('Other')}>Other</button>  
        
              {/* Add more category buttons as needed */}
            </div>
          )}
          {!isSmallScreen && (
            <div className="category-buttons">
              <h4>Categories</h4>
              <div style={{ display: 'flex', justifyContent: 'space-around', margin: '1px',  }}>
              <button onClick={() => handleCategoryClick('Sport')}>Sport</button>
              <button onClick={() => handleCategoryClick('Entertainment')}>Entertainment</button>
        <button onClick={() => handleCategoryClick('Blog')}>Blog</button>
        <button onClick={() => handleCategoryClick('Finance')}>Finance</button>
        <button onClick={() => handleCategoryClick('Travel and Hospitality')}>Travel</button>
        <button onClick={() => handleCategoryClick('Education and Academic')}>Education and Academic</button>
        <button onClick={() => handleCategoryClick('Health')}>Health</button>
        <button onClick={() => handleCategoryClick('Internet services')}>Internet services</button>
        <button onClick={() => handleCategoryClick('Design')}>Design</button>
        <button onClick={() => handleCategoryClick('E-commerce')}>E-commerce</button>
        <button onClick={() => handleCategoryClick('Business')}>Business</button>
        <button onClick={() => handleCategoryClick('Non-profit')}>Non-profit</button>
        <button onClick={() => handleCategoryClick('Other')}>Other</button>  
              {/* Add more category buttons as needed */}
            </div>
            </div>
          )}
        </div>
      )}
    <table style={tableStyle}>
        <thead>
          <tr>
            <th style={thStyle}>Domain Name</th>
            <th style={thStyle}>Price</th>
            <th style={thStyle}>Buy</th>
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                <th style={thStyle}>Type</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>View Profile</th>
                <th style={thStyle}>Registrar</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={isSmallScreen ? '3' : '7'}><LoadingSpinner /></td>
            </tr>
          ) : (
            filteredDomainsToDisplay.map((domain) => {
              const rows = [
                <tr key={domain.id}>
                  <td style={tdStyle}><strong>{domain.domainName}</strong></td>
                  <td style={tdStyle}><strong>{domain.currency}{domain.numeric}</strong></td>
                  <td style={tdStyle}>
                    <a href={domain.buyNowUrl} target="_blank" rel="noopener noreferrer">
                      <button style={buybtnStyle}>Buy</button>
                    </a>
                  </td>
                  {isSmallScreen && (
                    <td style={tdStyle}>
                      <button className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                        {expandedRows[domain.id] ? '-' : '+'}
                      </button>
                    </td>
                  )}
                  {!isSmallScreen && (
                    <>
                      <td style={tdStyle}>{domain.type}</td>
                      <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#ffffff', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', }}>
                        <Tooltip text={domain.description || 'No description is provided'}>
                          <button style={TooltipbtnStyle}>Read description</button>
                        </Tooltip>
                      </td>
                      <td style={tdStyle}>
                        <button onClick={() => showProfilePopup(domain.ownerId || 'defaultUserId')}>
                          <Icon icon="akar-icons:info-fill" width="24" height="24" />
                        </button>
                      </td>
                      <td style={tdStyle}>
                        <a href={`https://www.whatsmydns.net/domain-name-registrar?q=${domain.domainName}`} target="_blank" rel="noopener noreferrer">
                          <button style={RegistrarbtnStyle}>View Registrar</button>
                        </a>
                      </td>
                    </>
                  )}
                </tr>
              ];

              if (isSmallScreen && expandedRows[domain.id]) {
                rows.push(
                  <tr key={`${domain.id}-expanded`}>
                    <td colSpan={isSmallScreen ? '4' : '7'}>
                      <div>
                      <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
  <strong>{domain.domainName}</strong>
</p>
                        <p><strong>Type:</strong> {domain.type}</p>
                        <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                        <p>
  <strong>Seller Profile:</strong>{" "}
  <span
    style={{
      cursor: "pointer",
     
      color: "blue",
    }}
    onClick={() => showProfilePopup(domain.ownerId || "defaultUserId")}
  >
    View Profile
  </span>
</p>
                        <p><strong>Registrar:</strong> <a href={`https://www.whatsmydns.net/domain-name-registrar?q=${domain.domainName}`} target="_blank" rel="noopener noreferrer">
                          <button style={RegistrarbtnStyle}>View Registrar</button>
                        </a></p>
                       

                      </div>
                    </td>
                  </tr>
                );
              }

              return rows;
            })
          )}
        </tbody>
      </table>

      {isPopupVisible && (
        <div className="user-profile-popup">
          <Profile userId={selectedUserId} onClose={() => setPopupVisible(false)} />
        </div>
      )}
    </div>
  );
};



const allDomainsbtnStyle = {
  margin: '5px',
  padding: '6px 12px',
  fontSize: '12px',
  border: 'none',
  borderRadius: '5px',
  backgroundColor: '#4f4f4f',
  color: 'white',
  cursor: 'pointer'
};

const formatNumber = (number) => {
  return number.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};


const formattedMinValue = formatNumber(minNumeric);
const formattedMaxValue = formatNumber(maxNumeric);


const NxtNavbuttonStyle = {

  position: 'fixed',

  right: '10px',
 
  
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'right', 
 
    margin: '0 5px', // Margin between buttons
    padding: '8px', // Padding inside buttons
    cursor: 'pointer', // Cursor style
  };

  const PreNavbuttonStyle = {

    position: 'fixed',

  right: '40px',
 
  
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'right', 
 
    margin: '0 5px', // Margin between buttons
    padding: '8px', // Padding inside buttons
    cursor: 'pointer', // Cursor style
  };
  
  const disabledNxtNavButtonStyle = {
    ...NxtNavbuttonStyle,

    cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
    color: 'grey', // Set text color to grey for disabled buttons

  alignItems: 'center',
  justifyContent: 'center',
  };

  const disabledPreNavButtonStyle = {
    ...PreNavbuttonStyle,

    cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
    color: 'grey', // Set text color to grey for disabled buttons

  alignItems: 'center',
  justifyContent: 'center',
  };
  
  
  const NaviconStyle = {
    marginRight: '5px', // Margin between icon and text
  };

  const handlePreviousPage = () => {
    const newStartIndex = Math.max(startIndex - 15, 0);
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + 15);
  };

  const handleNextPage = () => {
    const newStartIndex = endIndex;
    setStartIndex(newStartIndex);
    setEndIndex(newStartIndex + 15);
  };


    return (
      <div>
<header>
        {/* Conditional rendering of the "Manage Profile" button */}
        {user ? (
          
          <Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ display: 'flex', alignItems: 'center', padding: '4px 8px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    
    Manage Marketplace Profile
  </button>
</Link>
        ) : (
          
          <Link to="/sign-in" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
  <button style={{ display: 'flex', alignItems: 'center', padding: '4px 8px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>
    
    Sign In to Marketplace
  </button>
</Link>
        )}
      </header>
      {/* Rest of your Marketplace content */}
      
      {isFilteringActive && (
      <div className="domain-filter">
      {/* Search inputs */}
      <input
  type="text"
  placeholder="Name or extension (e.g. .com)"
  value={nameFilter}
  onChange={(e) => setNameFilter(e.target.value)}
/>
      <select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
        <option value="All">All Types</option>
        <option value="Domain Only">Domain Only</option>
        <option value="Live Site">Live Site</option>
        {/* Add options for domain types */}
      </select>
      
      <select value={searchCurrency} onChange={(e) => setSearchCurrency(e.target.value)}>
          <option value="All">Select Currency</option>
          <option value="$">$/ USD /US Dollar</option>
          <option value="€">€/ EUR /Euro</option>
          <option value="£">£/ GBP /British Pound</option>
          <option value="¥">¥ /Yen</option>
          <option value="₣">₣ /Franc</option>
          <option value="₹">₹ /Rupee</option>
          <option value="₽">₽ /RUB /Russian Ruble</option>
          <option value="A$">A$ /AUD /Australian Dollar</option>
          <option value="C$">C$ /CAD /Canadian dollar</option>
          <option value="HK$">HK$ /Hong Kong Dollar</option>
          <option value="R$">R$ /BRL /Brazilian real</option>
          <option value="Mex$">Mex$ /MXN /Mexican Peso</option>
          <option value="R">R /South African Rand</option>
          <option value="د.إ"> د.إ /Dirham</option>
          <option value="د.ك"> د.ك /Dinar</option>
      </select>
      

      <input
  type="text"
  placeholder="Min Amount"
  value={minNumeric === '' ? '' : minNumeric.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
  })}
  onChange={(e) => {
    const enteredValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (enteredValue === '') {
      setMinNumeric(''); // Handle empty input by setting the value to an empty string
    } else if (!isNaN(enteredValue)) {
      setMinNumeric(parseInt(enteredValue, 10));
    }
    // If you want to handle invalid input differently, you can add an else block here.
  }}
/>


<input
  type="text"
  placeholder="Max Amount"
  value={maxNumeric === '' ? '' : maxNumeric.toLocaleString('en-US', {
    style: 'decimal',
    maximunFractionDigits: 0,
  })}
  onChange={(e) => {
    const enteredValue = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (enteredValue === '') {
      setMaxNumeric(''); // Handle empty input by setting the value to an empty string
    } else if (!isNaN(enteredValue)) {
      setMaxNumeric(parseInt(enteredValue, 10));
    }
    // If you want to handle invalid input differently, you can add an else block here.
  }}
/>



      <select value={searchCategory} onChange={(e) => setSearchCategory(e.target.value)}>
        <option value="All">All Categories</option>
      <option value="Sport">Sport</option>
          <option value="Entertainment">Entertainment</option>
          <option value="Blog">Blog</option>
          <option value="Finance">Finance</option>
          <option value="Travel and Hospitality">Travel and Hospitality</option>
          <option value="Education and Academic">Education and Academic</option>
          <option value="Health">Health</option>
          <option value="Internet services">Internet services</option>
          <option value="Design">Design</option>
          <option value="E-commerce and Online Stores">E-commerce and Online Stores</option>
          <option value="Business">Business</option>
          <option value="Non-profit">Non-profit</option>
          <option value="Other">Other</option>
        {/* Add options for domain categories */}
      </select>

       {/* Button to activate/deactivate filtering */}
       
<button onClick={applyFilters}>Filter Only</button>

      {/* ... Rest of your rendering logic ... */}
    </div>
    )}
     
    
<div>
   
    
</div>


      
      {/* Other content */}
      
      {/* Display the list of domains */}

      {/* Conditionally render the "Geno" button */}
    

<div>
  
      {showGeneralButton ? (
        <>
          
          {renderAllDomainsTable({ showGeneralButton, isFilteringActive, toggleFiltering: setIsFilteringActive })}
        </>
      ) : (
        <>

<button style={allDomainsbtnStyle} onClick={() => setShowGeneralButton(true)}>
            <h4>All Domains</h4>
        </button>
        <button
        style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
        onClick={handlePreviousPage}
        disabled={startIndex <= 0}
      >
        <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
      </button>

      {/* Next Button */}
      <button
        style={endIndex >= filteredDomains.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
        onClick={handleNextPage}
        disabled={endIndex >= filteredDomains.length}
      >
        <Icon icon="carbon:next-filled" color={endIndex >= filteredDomains.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
      </button>
          <h4>{selectedCategory ? `${selectedCategory} Domains` : 'General'}</h4>
          {renderDomainList({ showGeneralButton })}
        </>
      )}
    </div>
    {/* This is reserved to see users. 
    <div>
      <h1>Marketplace</h1>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            <Link to={`/profile/${user.id}`}>View Profile</Link>
          </li>
        ))}
      </ul>
        </div>*/}
    
   
    </div> 

    );
  }
  
  
  export default Marketplace;
