 // src/components/authentication/SignIn.js

 import React, { useState, useEffect } from "react";
 import { Link } from "react-router-dom";
 import { signInWithEmailAndPassword } from "firebase/auth";

 import { useNavigate } from "react-router-dom";
 import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
 
 
 import "./SignIn.css";
 import { Icon } from '@iconify/react';
 
 
 function SignIn() {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [user, setUser] = useState(null);

   const auth = getAuth();
   const [error, setError] = useState(null); // Initialize error state
   const navigate = useNavigate(); // Initialize useNavigate
 
 
   const handleSubmit = async (e) => {
     e.preventDefault();
     try {
       // Sign in with email and password
       await signInWithEmailAndPassword(auth, email, password);
       // Redirect to Profile.js after successful sign-in
       navigate("/dashboard");
       // You can use react-router for this navigation
       // Example: history.push("/profile"); 
     } catch (error) {
      console.error("Firebase Error:", error);

       
      // Set a custom error message based on the Firebase error code
      let errorMessage = "An error occurred during sign-in.";
      if (error.code === "auth/user-not-found") {
        errorMessage = "User not found. Please check your email.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Incorrect password. Please try again.";
      }


      setError(errorMessage);
    }
   };

   const handleEmailInput = () => {
    // Clear the error message when the user interacts with the email input
    setError(null);
  };

  const handlePasswordInput = () => {
    // Clear the error message when the user interacts with the password input
    setError(null);
  };

   const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  
    // Make sure to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);
 
  const homeButtonStyle = {

    position: 'fixed',
  
    left: '10px',
    top: '20px',
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
    };
  
 
   return (
     <main className="main">

<link
  href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap"
  rel="stylesheet"
/>


<div>


</div>
<div className="sign-in-parent-container">
<div className="header-account-settings">

  
         <section className="wrapper">
           <div className="heading">
           <p className="text text-normal">
               <span><Link to="/marketplace" className="text text-links">Retun to Marketplace</Link></span>
              </p>
             <h1 className="text text-large">Sign In to manage domains in marketplace</h1>
           </div>
           <form name="signin" className="form" onSubmit={handleSubmit}>
             <div className="input-control">
               <label htmlFor="email" className="input-label" hidden>
                 Email Address
               </label>
               <input
                 type="email"
                 name="email"
                 id="email"
                 className="input-field"
                 placeholder="Email Address"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 onFocus={handleEmailInput} // Clear error on focus required
               />
             </div>
             <div className="input-control">
               <label htmlFor="password" className="input-label" hidden>
                 Password
               </label>
               <input
                 type="password"
                 name="password"
                 id="password"
                 className="input-field"
                 placeholder="Password"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 onFocus={handlePasswordInput} // Clear error on focus required
               />
             </div>
             <div className="input-control">
               <Link to="/reset-password" className="text text-links">
                 Forgot Password
               </Link>
               <input
                 type="submit"
                 name="submit"
                 className="input-submit"
                 value="Sign In"
                 // Enable the submit button only when email and password are not empty
                 disabled={!email || !password}
               />
             </div>
           </form>
           {/* Display the error message if it exists */}
      {error && <div className="error-message">{error}</div>}
           <div className="striped">
             <span className="striped-line"></span>
             <span className="striped-text">Or</span>
             <span className="striped-line"></span>
           </div>
           <div className="method">
           <div className="method-control">
              {/* Use a function to open Google sign-in popup */}
              <button onClick={handleGoogleSignIn} className="method-action">
              <Icon icon="flat-color-icons:google" width="24" height="24" className="icon-before"/>
              <span className="button-text">Sign in with Google</span>
              
              </button>
            </div>
             <div className="method-control">
               <p className="text text-normal">
                 New user? <span><Link to="/sign-up" className="text text-links">Create an account</Link></span>
               </p>
             </div>
            
           </div>
         </section>
         </div>
       </div>
     </main>
   );
 }
 
 export default SignIn;